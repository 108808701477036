import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    public afAuth: AngularFireAuth
  ) {}


  loginConCorreo(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async recuperarPassword(email: string) {
    return await this.afAuth.sendPasswordResetEmail(email);
  }

  async logout() {
    return this.afAuth.signOut();
  }

}
