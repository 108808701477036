// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const ipApiExpuesta = 'https://apitracker-staging.dbs.cl:3010/api/v1';

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyC-ttxcgpT3dsjx9JlFGgbk6q1KiYXw-2w',
    authDomain: 'dbs-tracker-staging.firebaseapp.com',
    projectId: 'dbs-tracker-staging',
    storageBucket: 'dbs-tracker-staging.appspot.com',
    messagingSenderId: '603741013041',
    appId: '1:603741013041:web:fa327c8151ab40f57c0110'
  },
  ipApiBackend: ipApiExpuesta
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
