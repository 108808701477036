import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/menu/perfil/perfil.module').then(m => m.PerfilPageModule)
  },
  {
    path: 'mensajes',
    loadChildren: () => import('./pages/menu/mensajes/mensajes.module').then(m => m.MensajesPageModule)
  },
  {
    path: 'billetera',
    loadChildren: () => import('./pages/menu/billetera/billetera.module').then(m => m.BilleteraPageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./pages/menu/config/config.module').then(m => m.ConfigPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'recuperar-password',
    loadChildren: () => import('./pages/auth/recuperar-password/recuperar-password.module').then(m => m.RecuperarPasswordPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
