import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage | null = null;

  constructor(private storageService: Storage) {
    this.initStorage();
  }

  async initStorage() {
    const storage = await this.storageService.create();
    this.storage = storage;
  }

  public setVariableLocal(key: string, value: any) {
    return this.storage?.set(key, value);
  }

  public getVariableLocal(key: string) {
    return this.storage?.get(key);
  }

  public removerVariableLocal() {
    return this.storage?.clear();
  }
}
