import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class MensajesService {
  constructor() { }

  mensajeCarga(titulo: string, mensaje: string) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'info',
      showConfirmButton: false,
      timerProgressBar: true,
    });
  }

  mensajeInfo(titulo: string, mensaje: string) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'info',
      timer: 4000,
      confirmButtonColor: '#d33',
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar',
    });
  }

  mensajeError(titulo: string, mensaje: string) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'error',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      timer: 4000,
      allowOutsideClick: false,
    });
  }

  mensajeExito(titulo: string, mensaje: string) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'success',
      confirmButtonColor: '#1d365f',
      confirmButtonText: 'Aceptar',
      allowOutsideClick: false,
    });
  }

  mostrarLoading() {
    Swal.showLoading();
  }

  cerrarMensaje() {
    Swal.close();
  }

  async validarDecision(titulo: string, texto: string) {
    return await Swal.fire({
      title: titulo,
      html: texto,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#1d365f',
      confirmButtonText: 'Confirmar',
    });
  }
}
