import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { MensajesService } from './services/mensajes.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private auth: AuthService,
    private mjs: MensajesService,
    private router: Router,
    private menu: MenuController,
    private storageService: StorageService
  ) {
    // this.validarUsuario();
  }

  // async validarUsuario() {
  //   this.auth.afAuth.onAuthStateChanged(usuario => {
  //     if (usuario) {
  //       this.usuario = true;
  //       console.log('Usuario', usuario.displayName);
  //     } else {
  //       this.usuario = false;
  //       console.log('Usuario', usuario);
  //     }
  //   })
  //     .then()
  //     .catch(error => {
  //       console.error('Ha ocurrido un error', error);
  //     });
  // }

  async logout() {
    await this.auth.logout().then(() => {
      this.storageService.removerVariableLocal().then(
        () => {
          this.mjs.mensajeExito('Te has desconectado', 'Nos vemos pronto.');
          this.menu.close();
          this.router.navigate(['/login']);
        }
      );
    }).catch(() => {
      this.mjs.mensajeError('Ocurrio un error', 'No hemos podido cerrar tu sesión.');
    });;
  }
}
